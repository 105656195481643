<template>
	<v-sheet flat>
		<polling-navigation v-model="focus"></polling-navigation>
		<render-poll v-if="focus" :polls="pollsInFocus" v-bind="focus">
		</render-poll>
		<template v-else>
			<render-content color="transparent" id="pollintro"></render-content>
			<contact-information></contact-information>
		</template>
	</v-sheet>
</template>

<script>
	import ContactInformation from "@c/ui/ContactInformation";
	import PollingNavigation from "@c/polling/RightHandNavigation";
	import RenderPoll from "@c/polling/RenderPoll";
	import RenderContent from "@c/ui/RenderContent";
	export default {
		name: "Polls",
		data: () => {
			return {
				focus: null
			};
		},
		computed: {
			polls() {
				let polls = this.$store.getters["polls/myPolls"];
				return polls.filter((poll) => poll.status !== "submitted");
			},
			pollsInFocus() {
				const focus = this.focus;
				if (!focus) {
					return [];
				}
				return this.polls.filter(
					(p) => p.section == focus.section && p.service == focus.service
				);
			},
			organisation() {
				return this.$store.state.organisation.data;
			}
		},
		components: {
			RenderPoll,
			PollingNavigation,
			RenderContent,
			ContactInformation
		}
	};
</script>
