<template>
	<div>
		<v-progress-linear
			:value="percentage"
			color="green"
			@click="dialog = true"
		></v-progress-linear>
	</div>
</template>

<script>
	import _ from "lodash";
	export default {
		name: "PollResults",
		props: {
			answer: {type: String},
			question: {type: String}
		},
		data: () => {
			return {
				dialog: false
			};
		},
		computed: {
			polls() {
				return this.$store.getters["polls/byQuestion"](this.question);
			},
			total() {
				return this.polls.filter(
					(a) => a.status == "submitted" && a.data !== null
				).length;
			},
			count() {
				const self = this;
				let count = self.polls.filter((a) => self.answerMatched( a.data ));
				return count.length;
			},
			percentage() {
				return (this.count / this.total) * 100;
			}
		},
		methods: {
			answerMatched(value ){
				if( _.isArray(value) ){
					return value.includes(this.answer);
				}				
				return value == this.answer;
			}
		}
	};
</script>
