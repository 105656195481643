<template>
	<v-list-item v-if="sectionObj" @click="$emit('click')">
		
		<v-list-item-icon>
			<v-icon>mdi-check</v-icon>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title>
				{{ sectionObj.name }}<span v-if="service"> - {{serviceName}}</span>
			</v-list-item-title>
			<v-list-item-subtitle>
				<theme-chip v-if="sectionObj.theme" :id="section"></theme-chip>
			</v-list-item-subtitle>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
	import ThemeChip from "@c/navigation/assessment/sections/ThemeChip";
	export default {
		name: "PollNavigationItem",
		props: {
			section: String,
			service: String
		},
		computed: {
			sectionObj() {
				return this.$store.state.sections.data[this.section];
			},
			serviceName() {
				if (!this.service) {
					return "";
				}
				return this.$store.getters["categoryOptions/getName"](this.service);
			}
		},
		components: {
			ThemeChip
		}, 
	};
</script>
