<template>
	<v-navigation-drawer
		v-model="drawer"
		:mini-variant="mini"
		permanent
		app
		right
		style="z-index: 101"
		touchless
		width="350px"
	>
		<template v-if="!polls.length">
			No polls found
		</template>
		<template v-else>
			<v-list dense>
				<polling-nav-item
					v-for="(item, i) in items"
					:key="i"
					v-bind="item"
					@click="select(item)"
				>
				</polling-nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>

<script>
	import _ from "lodash";
	import PollingNavItem from "@c/polling/NavItem";
	export default {
		name: "PollNavigation",
		props: {
			value: Object
		},
		data: () => {
			return {
				drawer: true,
				mini: false
			};
		},
		computed: {
			polls() {
				let polls = this.$store.getters["polls/myPolls"];
				return polls.filter((poll) => poll.status !== "submitted");
			},
			sections() {
				return this.$store.state.sections.data;
			},
			order() {
				return this.$store.state.assessment.data.responses;
			},
			items() {
				const order = Object.keys(this.order);
				const items = this.polls.map((p) => ({
					section: p.section,
					service: p.service
				}));
				const unique = _.uniqWith(items, _.isEqual);
				return unique.sort((a, b) => {
					return order.indexOf(a.section) > order.indexOf(b.section);
				});
			}
		},
		components: {
			PollingNavItem
		},
		methods: {
			select(item) {
				this.$emit("input", item);
			}
		}
	};
</script>
