<template>
	<v-sheet>
		<h2 class="font-weight-light">
			{{ sectionObj.name }}
		</h2>
		<h4 class="subtitle-1" v-if="service">
			{{ serviceObj.name }}
		</h4>
		<template v-if="!saved">
			<template v-if="polls.length">
				<questions poll :ids="questions" v-model="data"></questions>
				<p class="text-right">
					<v-btn @click="submit" color="primary">{{
						$t("buttons.submit")
					}}</v-btn>
				</p>
			</template>
			<template v-else>
				{{ $t("collaboration.polls.not_found") }}
			</template>
		</template>
		<template v-else>
			<v-alert type="info" text>
				{{ $t("collaboration.polls.thank_you") }}
			</v-alert>
		</template>
	</v-sheet>
</template>

<script>
	import Questions from "@c/survey/render/Questions";
	export default {
		name: "Poll",
		props: {
			section: String,
			service: String,
			polls: Array
		},
		data: () => {
			return {
				data: {},
				saved: false
			};
		},
		computed: {
			sectionObj() {
				return this.$store.state.sections.data[this.section];
			},
			serviceObj() {
				return this.$store.state.categoryOptions.data[this.service];
			},
			questions() {
                const self = this;
				let qs = self.polls.map((poll) => poll.question);
				return self.sectionObj.questions.filter((question) => qs.includes(question));
			}
		},
		components: {
			Questions
		},
		watch: {
			section() {
				this.reset();
			},
			service() {
				this.reset();
			}
		},
		methods: {
			reset() {
				this.saved = false;
				this.data = {};
			},
			async submit() {
				const self = this;
				Object.keys(self.data).forEach((q) => {
					let polls = self.polls.filter((poll) => poll.question == q);
					polls[0].data = self.data[q];
					polls.forEach(async (poll) => {
						await self.$store.dispatch("polls/patch", {
							id: poll.id,
							data: poll.data || null,
							status: "submitted"
						});
					});
					self.saved = true;
				});
			}
		}
	};
</script>
