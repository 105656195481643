<template>
	<v-row v-if="question" :class="{'d-none': question.always_hidden}" :id="id">
		<v-col>
			<div
				class="mw-question-wrapper"
				:class="{'conference-question': isConferenceQuestion}"
				v-if="questionTemplate"
			>
				<question-title
					:id="id"
					:staffSurvey="staffSurvey"
					:poll="poll"
				></question-title>
				<i class="mw-question-description">{{
					questionTemplate.description
				}}</i>
				<div class="mw-question-body">
					<v-component
						:is="componentName"
						:disabled="disabled"
						:id="question.question_template"
						:value="value"
						:multiple="questionTemplate.multiple"
						@update="(v) => $emit('input', v)"
					>
						<template v-slot:pollResults="{answer}" v-if="canPoll">
							<poll-results :question="id" :answer="answer"></poll-results>
						</template>
					</v-component>
				</div>
				<div class="mw-question-footer"></div>
			</div>
		</v-col>
	</v-row>
</template>

<style lang="less">
	.mw-question-description {
		font-size: 0.8em;
	}
</style>

<script type="text/javascript">
	import PollResults from "@c/collaboration/PollResults";
	export default {
		name: "Question",
		props: {
			id: {},
			value: {type: [Array, Object, String, Number], default: null},
			staffSurvey: {type: Boolean},
			poll: {type: Boolean},
			disabled: {type: Boolean}
		},
		components: {
			PollResults,
			QuestionTitle: () => import("@c/survey/render/QuestionTitle"),
			Comment: () => import("@c/survey/questionTypes/Comment"),
			Html: () => import("@c/survey/questionTypes/Html"),
			RadioTiles: () => import("@c/survey/questionTypes/RadioTiles"),
			Checkboxes: () => import("@c/survey/questionTypes/Checkboxes"),
			Input: () => import("@c/survey/questionTypes/Input")
		},
		computed: {
			question() {
				return this.$store.state.questions.data[this.id];
			},
			conference() {
				return this.$store.state.conference.data;
			},
			isConferenceQuestion() {
				return this.conference.status == "active" && this.conference.question == this.id;
			},
			questionTemplate() {
				if (!this.question) {
					return;
				}
				var id = this.question.question_template;
				return this.$store.state.questionTemplates.data[id];
			},
			indicator() {
				if (!this.question) {
					return;
				}
				var id = this.question.indicator;
				return this.$store.state.indicators.data[id];
			},
			componentName() {
				let types = {
					tiles: "RadioTiles",
					comment: "Comment",
					checkboxes: "Checkboxes",
					html: "Html",
					input: "Input"
				};

				return types[this.questionTemplate.type];
			},
			canPoll() {
				return this.$store.getters.canPoll && this.polls.length;
			},
			polls() {
				return this.$store.getters["polls/byQuestion"](this.id);
			},
			response() {
				return this.$store.state.sectionResponses.data[
					this.$store.state.current.response
				];
			},
			calculation() {
				if (this.question && this.question.calculation) {
					return this.mwsurveyutils.calculateValue(
						this.question.calculation,
						this.response
					);
				}
				return false;
			}
		},
		watch: {
			calculation: {
				immediate: true,
				handler(v) {
					if (v) {
						// console.log( v )
						this.$emit("input", v);
					}
				}
			}
		}
	};
	//
</script>
"
